import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const ButtonWrap = styled.div`
  width: 14rem;
`;

export const MailBtn = styled.a<{ isHostedPage?: boolean }>`
  padding: 0 0.8rem;
  border-radius: 0.4rem;
  height: ${({ isHostedPage }) => (isHostedPage ? '4.73rem' : '3.75rem')};
  ${Mixin.VerticalCenter()};
  ${({ theme: { ApplyBtnStyle } }) =>
    ApplyBtnStyle &&
    css`
      background-color: ${ApplyBtnStyle.BgColors.mailBtn};
      color: ${ApplyBtnStyle.Colors.mailBtn};

      &:hover {
        background-color: ${ApplyBtnStyle.BgColors.mailBtnHover};
        color: ${ApplyBtnStyle.Colors.mailBtn};
      }
    `}

  ${device.mobileLg} {
    height: 3.435rem;
  }
`;

export const Title = styled.span<{ isHostedPage?: boolean }>`
  font-weight: 500;
  ${({ theme: { ApplyBtnStyle }, isHostedPage }) =>
    ApplyBtnStyle &&
    css`
      font-size: ${ApplyBtnStyle.FontSizes.title};
      line-height: ${isHostedPage ? ApplyBtnStyle.LineHeights.title : 1};
    `}
  display: block;

  ${device.mobileLg} {
    ${Mixin.Position({ position: 'relative', value: '0.3rem auto auto' })};
  }
`;

export const TextWrap = styled.label<{ isHostedPage?: boolean }>`
  ${({ theme: { ApplyBtnStyle }, isHostedPage }) =>
    ApplyBtnStyle &&
    css`
      font-size: ${ApplyBtnStyle.FontSizes.textWrap};
      line-height: ${isHostedPage ? ApplyBtnStyle.LineHeights.textWrap : 1};
      ${device.mobileLg} {
        font-size: ${ApplyBtnStyle.FontSizes.textWrapMb};
      }
    `}
  font-weight: 500;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 38rem;
  height: 100%;
  ${Mixin.AllCenter({ direction: 'column', gap: '0' })};
  min-width: 12.4rem;

  ${device.mobileLg} {
    max-width: 15rem;
  }
`;

export const IconWrap = styled.span`
  display: inline-block;
  padding-right: 0.8rem;
`;
export const PopupFooter = styled.div`
  padding: 1.2rem 1.6rem;
  ${({ theme: { ApplyBtnStyle } }) =>
    ApplyBtnStyle &&
    css`
      border-top: 0.1rem solid ${ApplyBtnStyle.BdrColors.popupFooter};
    `}
  ${Mixin.HorizontalCenter()};
  column-gap: 1rem;
`;
