import { ApplyJobStatus } from '@components/common/constant';
import Toaster from '@components/common/toaster';
import { Button, formatDate } from '@license-admin/boldfjcomponents';
import useMediaQuery from '@utils/useMediaQuery';

import { addJobsCountTraits } from '@components/common/event-tracker';
import { FormCheck } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import {
  deleteJobNoteById,
  getJobNoteById,
  postSaveJobs,
  saveJobNoteService,
} from 'API/ApiHelpers';
import { applyJob } from 'API/jobs';
import { USER_TRAITS_JOBS_COUNT } from 'helper/eventTrackingUtils/constant';
import { useEffect, useState } from 'react';
import {
  ActionBtnWrapper,
  ConfirmDelText,
  DeleteIcon,
  Icon,
  JobNoteAction,
  NoteBtnWrapper,
  NoteDelWrapper,
  NoteHeadWrap,
  NoteHeader,
  NoteLabel,
  NotePencilWrap,
  StickyIcon,
  TextArea,
  TextAreaWrapper,
  WriteText,
} from './styled';

type Props = {
  jobId: string;
  appliedDate?: string | null;
  applyJobStatus?: string;
  savedJobsCommons: any;
  isHostedPage?: boolean;
  deviceType?: string;
  isVariant?: boolean;
};

const JobNotes = (props: Props) => {
  const {
    jobId,
    applyJobStatus,
    appliedDate,
    isHostedPage,
    deviceType,
    isVariant,
  } = props;
  const isMobileView = useMediaQuery(width.tabletSm) || deviceType === 'mobile';
  const [isNoteTextFocused, setIsNoteTextFocued] = useState(false);
  const [notesToggles, setNotesToggles] = useState({
    notesTab: true,
    showAlreadyAppliedToast: false,
    isConfirmDel: false,
  });

  const [noteVal, setNoteVal] = useState('');
  const [noteValRes, setNoteValRes] = useState('');
  const [applyJobCheckbox, setApplyJobCheckbox] = useState({
    iconCheck: false,
    jobStatus: applyJobStatus?.toLowerCase() || 'None',
  });

  useEffect(() => {
    setIsNoteTextFocued(false);
    setNoteVal('');
    const isApplied =
      applyJobStatus?.toLowerCase() === ApplyJobStatus.applied.toLowerCase();
    setApplyJobCheckbox({
      iconCheck: isApplied,
      jobStatus: applyJobStatus?.toLowerCase() || 'None',
    });
    setNotesToggles((prevState: any) => ({
      ...prevState,
      showAlreadyAppliedToast: isApplied,
    }));
  }, [jobId]);

  useEffect(() => {
    const getNote = async (jobId: string) => {
      const responseNote = await getJobNoteById(jobId);
      if (responseNote.success) {
        setNoteValRes(responseNote.data.note || '');
        setNoteVal(responseNote.data.note || '');
      }
    };
    getNote(jobId);
  }, [jobId]);

  const setApplyCheckBoxStatus = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.checked) {
      setApplyJobCheckbox({
        iconCheck: true,
        jobStatus: ApplyJobStatus.applied.toLowerCase(),
      });
    } else {
      setApplyJobCheckbox({
        iconCheck: false,
        jobStatus: 'None',
      });
    }
  };

  const deleteConfirm = async () => {
    try {
      await deleteJobNoteById(jobId);
      setIsNoteTextFocued(false);
      setNotesToggles((prevState: any) => ({
        ...prevState,
        isConfirmDel: false,
      }));
      setNoteVal('');
    } catch (error) {
      throw new Error('Failed to delete Note' + JSON.stringify(error));
    }
  };

  const saveNoteHandler = async () => {
    const body = {
      jobId: jobId,
      note: noteVal,
    };
    try {
      Promise.all([
        saveJobNoteService(body),
        postSaveJobs(jobId, true),
        applyJob(jobId, applyJobCheckbox.jobStatus),
      ]).then(() => {
        props.savedJobsCommons.setSavedJobsArray([
          ...props.savedJobsCommons.savedJobsArray,
          jobId,
        ]);
        setIsNoteTextFocued(false);
        setNoteValRes(noteVal);
      });
      addJobsCountTraits(USER_TRAITS_JOBS_COUNT.JOB_APPLICATIONS);
    } catch (error) {
      throw new Error('Save Operation Failed' + JSON.stringify(error));
    }
  };

  const changeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNoteVal(e.target.value);
  };

  const cancelToaster = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setNotesToggles((prevState: any) => ({
      ...prevState,
      showAlreadyAppliedToast: false,
    }));
  };

  const writeNote = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsNoteTextFocued(true);
  };

  const cancelHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsNoteTextFocued(false);
    noteValRes.length > 0 ? setNoteVal(noteValRes) : setNoteVal('');
  };

  return (
    <>
      {isMobileView && !isHostedPage && !isVariant && (
        <NoteHeadWrap onClick={writeNote}>
          <StickyIcon
            className={
              noteVal !== ''
                ? 'fa-solid fa-note-sticky'
                : 'fa-regular fa-note-sticky fa-gray'
            }
          ></StickyIcon>
          <WriteText>Write Note</WriteText>
        </NoteHeadWrap>
      )}
      {!isMobileView || noteVal || notesToggles.notesTab ? (
        <>
          {notesToggles.showAlreadyAppliedToast && (
            <Toaster
              icon="fa-regular fa-circle-check"
              status="info"
              description={`You have already applied for this job. <a href="/members/jobapplications"> Manage Job Applications</a>.`}
              cancelToaster={cancelToaster}
              cancelIcon={true}
              role="alert"
            />
          )}
          <TextAreaWrapper
            isNoteVal={noteVal}
            isTextFocus={isNoteTextFocused}
            onFocus={() => setIsNoteTextFocued(true)}
          >
            <NoteHeader id={'job-note-container'}>
              <NotePencilWrap>My Notes</NotePencilWrap>
              {isNoteTextFocused ? (
                <div className="applyCheckbox">
                  <FormCheck
                    className="applyCheckbox"
                    id="applyCheckbox"
                    key={Math.random()}
                    type="checkbox"
                    checkInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setApplyCheckBoxStatus(e)
                    }
                    checked={applyJobCheckbox.iconCheck}
                  />
                  <NoteLabel>Have you applied to this job?</NoteLabel>
                </div>
              ) : (
                appliedDate && (
                  <NoteLabel id={'note-label' + jobId}>
                    <Icon className="fa fa-check" />
                    {`Applied On ${formatDate(appliedDate || '', 'shortDate')}`}
                  </NoteLabel>
                )
              )}
            </NoteHeader>
            <TextArea
              onChange={changeHandler}
              value={noteVal}
              placeholder="Write down any notes or thoughts about this job for reference later."
              className={
                isNoteTextFocused ? 'height-adjust-max' : 'height-adjust-min'
              }
              id={'text-area' + jobId}
            ></TextArea>
            {isNoteTextFocused && (
              <JobNoteAction>
                <Button
                  buttonType="primary"
                  text="Save"
                  clickHandler={saveNoteHandler}
                  id={'save-btn-' + jobId}
                />
                <Button
                  buttonType="transparent"
                  text="Cancel"
                  clickHandler={cancelHandler}
                  ButtonClass="cancel-btn"
                  id={'cancel-btn-' + jobId}
                />
                <DeleteIcon
                  id={'delete-btn-' + jobId}
                  onClick={() =>
                    setNotesToggles((prevState: any) => ({
                      ...prevState,
                      isConfirmDel: true,
                    }))
                  }
                  className="fa fa-trash-can"
                ></DeleteIcon>
              </JobNoteAction>
            )}
            {notesToggles.isConfirmDel && (
              <NoteDelWrapper id={'confirm-popup-' + jobId}>
                <ConfirmDelText>
                  Are you sure you want to delete this note?
                </ConfirmDelText>
                <ActionBtnWrapper>
                  <NoteBtnWrapper>
                    <Button
                      buttonSize={isMobileView ? 'sm' : 'md'}
                      buttonType="primary"
                      text="Yes, Delete"
                      ButtonClass="action-btn"
                      underlineOnHover
                      clickHandler={() => deleteConfirm()}
                      id={'note-delete-' + jobId}
                    ></Button>
                  </NoteBtnWrapper>
                  <NoteBtnWrapper>
                    <Button
                      buttonSize={isMobileView ? 'sm' : 'md'}
                      buttonType="transparent"
                      text="Cancel"
                      ButtonClass="action-btn"
                      underlineOnHover
                      clickHandler={() =>
                        setNotesToggles((prevState: any) => ({
                          ...prevState,
                          isConfirmDel: false,
                        }))
                      }
                      id={'note-cancel-' + jobId}
                    ></Button>
                  </NoteBtnWrapper>
                </ActionBtnWrapper>
              </NoteDelWrapper>
            )}
          </TextAreaWrapper>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default JobNotes;
