import { jobSearchResultTrackEvent } from '@components/common/event-tracker';
import { Button } from '@license-admin/boldfjcomponents';
import { width } from '@styles/device.styled';
import useMediaQuery from '@utils/useMediaQuery';
import { getCompaniesHiringCount } from 'API/jobs';
import { getSearchType } from 'helper/eventTrackingUtils';

import { getNumFormatter } from '@components/common';
import { isRCPortal } from 'helper';
import {
  Action,
  ClickOption,
  ViewType,
} from 'helper/eventTrackingUtils/constant';
import useLazyLoad from 'helper/useLazyLoad';
import LazyImage from 'helper/useLazyLoadImages';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import {
  AuthourHighlight,
  AuthourText,
  BannerQuote,
  BannerUnlockWrap,
  CardContent,
  CardWrapper,
  LockArrow,
  MobWrapper,
  MobileImage,
  QuoteBox,
  QuoteWrapper,
  RightImageWrap,
  RightSideImg,
  SiteJabberDesktop,
  Title,
  UnlockIcon,
  VerifiedInfo,
} from './styled';

interface Props {
  imgSrc: string;
  unlock_banner_text: any;
  isHostedPage?: boolean;
  jobDetails?: any;
  jobNumber?: number;
  jobsData?: any;
  queryParams?: any;
  guid?: string;
  className?: string;
  isPremiumUser?: boolean;
  isHybrid?: boolean;
  deviceType?: string;
}

const createAccountEventTrigger = (props: Props, router: any) => {
  const mergerdQueries = {
    ...props?.queryParams,
    ...router.query,
  };
  jobSearchResultTrackEvent(
    Action.Clicked,
    getSearchType(),
    props.isHostedPage ? ViewType.Separate_Tab : ViewType.Job_Post_Details,
    props.jobDetails,
    !props.isHostedPage && props.jobsData,
    mergerdQueries,
    ClickOption.Create_Account,
    props.jobNumber,
    props.guid
  );
};

const BannerUnlock = (props: Props) => {
  const router = useRouter();
  const blob_path = `${process.env.NEXT_PUBLIC_CONTENT_URL}/images`;
  const isMobileView =
    useMediaQuery(width.tablet) || props.deviceType === 'mobile';
  const [jobsCount, setJobsCount] = useState(20000);
  const numFormat = getNumFormatter();

  useEffect(() => {
    getJobCount();
  }, []);

  const getJobCount = async () => {
    const companiesHiringCount = await getCompaniesHiringCount();
    if (companiesHiringCount?.jobsCount)
      setJobsCount(companiesHiringCount.jobsCount);
  };
  const lazy = useLazyLoad();
  const bannerUnlockBenefitsText = isRCPortal
    ? props.unlock_banner_text.benefitsForRC
    : props.unlock_banner_text.benefits;

  const bannerUnlockBenefitsTextMob = isRCPortal
    ? props.unlock_banner_text.benefitsForRC
    : props.unlock_banner_text.benefits_mobile;
  return (
    <>
      <BannerUnlockWrap
        href="/signup/"
        onClick={() => createAccountEventTrigger(props, router)}
        className={props.className}
        isRCPortal={isRCPortal}
      >
        {!props.isHybrid && (
          <>
            <UnlockIcon className="unlock-lock">
              <LazyImage
                imgSrc={`${blob_path}/new-lock.svg`}
                imgAlt="icon lock"
                imgWidth={30}
                imgHeight={29}
              />
            </UnlockIcon>
            <LockArrow>
              <LazyImage
                imgSrc={`${blob_path}/lock-arrow.svg`}
                imgAlt="lock-arrow"
                imgWidth={44}
                imgHeight={58}
              />
            </LockArrow>
          </>
        )}
        <CardWrapper
          isRCPortal={isRCPortal}
          isBgimage={isRCPortal}
        >
          <CardContent
            isHostedPage={props.isHostedPage}
            isRCPortal={isRCPortal}
          >
            <div className="left-section">
              <MobWrapper>
                <Title
                  className={lazy ? 'visible' : 'lazy'}
                  dangerouslySetInnerHTML={{
                    __html: props.isHybrid
                      ? props.unlock_banner_text.join_hybrid.replace(
                          '{0}',
                          numFormat.format(jobsCount)
                        )
                      : isMobileView
                      ? props.unlock_banner_text.join_mobile
                      : props.unlock_banner_text.join,
                  }}
                  as={props.isHostedPage || isRCPortal ? 'h1' : 'h3'}
                  isRCPortal={isRCPortal}
                />

                {isMobileView && (
                  <MobileImage
                    src={props.imgSrc}
                    alt="job seeker image"
                    width={129}
                    height={143}
                    style={{ aspectRatio: 129 / 143 }}
                  />
                )}
              </MobWrapper>
              {isMobileView && (
                <Button
                  text={props.unlock_banner_text.signup_and_find}
                  buttonType="tertiary"
                  fullLength
                  buttonSize="md"
                  ButtonClass="signup-btn"
                />
              )}

              <ul className="benefit-ul">
                {isMobileView
                  ? bannerUnlockBenefitsTextMob.map((item: any) => (
                      <li key={item}>
                        <i className="fa fa-check"></i>
                        {item.replace('{0}', numFormat.format(jobsCount))}
                      </li>
                    ))
                  : bannerUnlockBenefitsText.map((item: any) => (
                      <li key={item}>
                        <i className="fa fa-check"></i>
                        {item.replace('{0}', numFormat.format(jobsCount))}
                      </li>
                    ))}
              </ul>
              {props.isHostedPage && !isMobileView && (
                <Button
                  text={props.unlock_banner_text.signup_and_find}
                  buttonType="tertiary"
                  buttonSize="md"
                />
              )}
            </div>
            {!isMobileView && (
            <>
              {!props.isHostedPage && (
                <Button
                  text={props.unlock_banner_text.signup_and_find}
                  buttonType="tertiary"
                  buttonSize="lg"
                  fullLength
                  ButtonClass="find-you-remote-job"
                />
              )}
              {!isRCPortal && (
                <QuoteBox isHostedPage={props.isHostedPage}>
                  <BannerQuote
                    className={lazy ? 'visible' : 'lazy'}
                    dangerouslySetInnerHTML={{
                      __html: props.unlock_banner_text.best_site_quote,
                    }}
                  />
                  <QuoteWrapper>
                    <AuthourText>
                      <AuthourHighlight>
                        -{props.unlock_banner_text.author_name}
                      </AuthourHighlight>
                      <span> {props.unlock_banner_text.address}</span>
                    </AuthourText>
                    <VerifiedInfo className={lazy ? 'visible' : 'lazy'}>
                      {props.unlock_banner_text.verified_member}
                    </VerifiedInfo>
                  </QuoteWrapper>
                </QuoteBox>
              )}
            </>
          )}
          </CardContent>
          
        </CardWrapper>
      </BannerUnlockWrap>
    </>
  );
};

export default BannerUnlock;
