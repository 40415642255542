import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

interface PopoverProps {
  flex?: boolean;
}

export const PopoverWrap = styled.div<{
  toggleStatus?: boolean;
  mobTransform?: string;
}>`
  ${Mixin.Position({ value: 'auto auto auto 0' })};
  transform: translate(5%, -25%);
  z-index: 1;
  width: 58rem;
  border-radius: 0.5rem;
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      font-size: ${jobGuideInfoStyle.FontSizes.wrap};
      background-color: ${jobGuideInfoStyle.BgColors.wrap};
      border: 0.1rem solid ${jobGuideInfoStyle.BdrColors.wrap};
      line-height: ${jobGuideInfoStyle.LineHeight.wrap};
    `}
  ${device.mobileTab} {
    ${Mixin.Position({ value: '3.7rem 0 auto 0' })};
    transform: translate(-25%, 0%);
    max-width: 50rem;
  }

  ${device.mobileLg} {
    ${Mixin.Position({ value: '3.1rem 0 auto 0' })};
    transform: ${({ mobTransform }) =>
      mobTransform ? `translate(${mobTransform})` : ' translate(-90%, 0%)'};
    max-width: 31rem;
  }
  opacity: ${({ toggleStatus }) => (toggleStatus ? 1 : 0)};
  visibility: ${({ toggleStatus }) => (toggleStatus ? 'visible' : 'hidden')};
  max-height: ${({ toggleStatus }) => (toggleStatus ? 'auto' : 0)};
  transition: opacity 0.3s ease-in-out;
`;

export const PopoverTitle = styled.h5`
  padding: 0.8rem 1.6rem;
  margin: 0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      background-color: ${jobGuideInfoStyle.BgColors.header};
      border-bottom: 0.1rem solid ${jobGuideInfoStyle.BdrColors.header};
    `}
`;

export const PopoverBody = styled.div`
  padding: 1.6rem;
`;

export const PopoverHeader = styled.div`
  margin-bottom: 1.6rem;
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      color: ${jobGuideInfoStyle.Colors.popoverHeader};
      font-weight: ${jobGuideInfoStyle.FontWeight.popoverHeader};
    `}
`;

export const IconGuideList = styled.ul<PopoverProps>`
  list-style: none;
  margin: ${({ flex }) => (flex ? 0 : '0 0 1.6rem')};
  padding: 0;
`;

export const ListItemIcon = styled.i`
  margin-right: 1.2rem;
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      &:before {
        content: ${jobGuideInfoStyle.Icons.locationIcon};
      }
    `}
`;

export const ListItem = styled.li`
  ${Mixin.VerticalCenter()};
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      color: ${jobGuideInfoStyle.Colors.listItem};
      font-size: ${jobGuideInfoStyle.FontSizes.listItem};
      font-weight: ${jobGuideInfoStyle.FontWeight.listItem};
    `}
`;

export const ListItemImgGlobal = styled.div`
  display: inline;
  margin: 0 0.5rem 0 0;
`;

export const ListItemFlag = styled.div<{ isLocationFlag?: boolean }>`
  display: inline;
  margin: ${({ isLocationFlag }) =>
    isLocationFlag ? '0 0.2rem' : '0 0 0 0.5rem'};
  ${device.mediumScreen} {
    vertical-align: sub;
  }
`;

export const ListItemDesc = styled.p`
  &:last-child {
    margin-bottom: 0;
  }
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      line-height: ${jobGuideInfoStyle.LineHeight.desc};
    `}
`;

export const Arrow = styled.div<{ toggleStatus?: boolean }>`
  opacity: ${({ toggleStatus }) => (toggleStatus ? 1 : 0)};
  visibility: ${({ toggleStatus }) => (toggleStatus ? 'visible' : 'hidden')};
  ${Mixin.Position({ value: '30% auto auto 2.2rem' })};
  &::before,
  &::after {
    position: absolute;
    display: block;
    content: '';
    border-color: transparent;
    border-style: solid;
  }
  &::before {
    left: 0;
    border-width: 0.8rem 0.8rem 0.8rem 0;
  }
  &::after {
    left: 0.1rem;
    border-width: 0.8rem 0.8rem 0.8rem 0;
  }
  ${({ theme: { jobGuideInfoStyle } }) =>
    jobGuideInfoStyle &&
    css`
      &::before {
        border-right-color: ${jobGuideInfoStyle.BdrColors.popoverArrowBefore};
      }
      &::after {
        border-right-color: ${jobGuideInfoStyle.BdrColors.popoverArrow};
      }
    `}
  ${device.mobileLg} {
    inset: 2.2rem 2% auto auto;
    transform: rotate(90deg);
  }
  ${device.tabletSm} {
    ${Mixin.Position({ value: '2.9rem 0 auto auto' })};
    transform: rotate(90deg);
  }
`;
