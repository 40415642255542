import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const NoteHeader = styled.div`
  ${Mixin.AllCenter({ justify: 'space-between' })};
  margin-bottom: 1rem;
  padding-left: 1.5rem;
  span {
    ${({ theme: { JobInfoFooterStyle } }) =>
      JobInfoFooterStyle &&
      css`
        font-weight: ${JobInfoFooterStyle.FontWeight.notesHeader};

        i {
          margin-right: 0.5rem;
        }
      `}
  }

  .applyCheckbox {
    ${Mixin.VerticalCenter({ align: 'center' })};
    column-gap: 0.2rem;
    ${({ theme: { JobInfoFooterStyle } }) =>
      JobInfoFooterStyle &&
      css`
        font-weight: ${JobInfoFooterStyle.FontWeight.semibold};
      `}
    ${device.mobileLg} {
      ${Mixin.VerticalCenter({ align: 'flex-start' })};
    }
  }
`;

export const JobNoteAction = styled.div<{ isJobApply?: boolean }>`
  ${Mixin.VerticalCenter()};
  column-gap: 1rem;
  ${({ isJobApply }) =>
    isJobApply &&
    css`
      .buttondark {
        ${({ theme: { JobInfoFooterStyle } }) =>
          JobInfoFooterStyle &&
          css`
            color: ${JobInfoFooterStyle.Colors.colorDark};
            font-weight: ${JobInfoFooterStyle.FontWeight.notesHeader};
          `}
      }
    `}
  .cancel-btn-note {
    ${({ theme: { JobInfoFooterStyle } }) =>
      JobInfoFooterStyle &&
      css`
        color: ${JobInfoFooterStyle.Colors.JobNoteActionBtn};
      `}
  }
`;

export const JobActionWrapper = styled(JobNoteAction)<{ isRCPortal?: boolean }>`
  ${({ isRCPortal }) =>
    isRCPortal
      ? css`
          margin: 2.4rem 0 4rem;
          padding: 0;
        `
      : css`
          padding: 1.6rem 0;
        `};

  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      .apply-btn-job,
      .save-job-btn {
        padding: 1.134rem 2.4rem;
        font-size: 1.6rem;
        line-height: 1;
      }
    `}
  .align-right-btn {
    margin-left: auto;
    ${({ isRCPortal }) =>
      isRCPortal &&
      css`
        font-weight: 600;
        min-width: auto;
        padding: 0;
      `}
  }
  ${({ isRCPortal }) =>
    isRCPortal &&
    css`
      grid-row-start: 1;
    `}
  ${device.mobileLg} {
    column-gap: 0.5rem;
    .apply-btn-job {
      ${({ theme: { JobInfoFooterStyle } }) =>
        JobInfoFooterStyle &&
        css`
          font-size: ${JobInfoFooterStyle.FontSizes.applybtn};
        `}
      .fa-share-from-square {
        display: none;
      }
    }
    .save-job-btn {
      min-width: 10.5rem;
    }
  }
`;
export const JobDetailsWrapper = styled.div`
  .align-right-btn {
    margin-left: auto;
    font-weight: 600;
    min-width: auto;
    padding: 0;
  }
`;
export const DeleteIcon = styled.span`
  margin-left: auto;
  cursor: pointer;
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      color: ${JobInfoFooterStyle.Colors.deleteIcon};

      i {
        font-size: ${JobInfoFooterStyle.FontSizes.deleteIcon};
        font-weight: ${JobInfoFooterStyle.FontWeight.deleteIcon};
      }
    `}
`;

export const TextAreaWrapper = styled.div`
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      border: 0.1rem solid ${JobInfoFooterStyle.BdrColors.textAreaWrap};
    `}
  padding: 1.5rem;
  border-radius: 0.4rem;
  position: relative;
`;

export const TextArea = styled.textarea`
  width: 100%;
  background: none;
  border: none;
  resize: none;
  padding: 0.6rem 1.5rem;
  margin-bottom: 2.5rem;
  height: 7rem;
  font-family: "Open Sans", Raleway, Arial, sans-serif;
  outline: none;
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      font-size: ${JobInfoFooterStyle.FontSizes.textArea};
    `}
`;

export const ReportJob = styled.a<{
  reportedJob?: boolean;
  isHostedPage?: boolean;
}>`
  display: inline-block;
  cursor: pointer;
  margin: ${({ isHostedPage }) =>
    isHostedPage ? '0.8rem 0 1.6rem' : '2rem 0'};
  ${({ theme: { JobInfoFooterStyle }, reportedJob }) =>
    JobInfoFooterStyle &&
    css`
      color: ${reportedJob
        ? JobInfoFooterStyle.Colors.reportedLab
        : JobInfoFooterStyle.Colors.reportLab};

      i {
        margin-right: 0.6rem;
      }
    `}
  &:hover {
    ${({ theme: { JobInfoFooterStyle }, reportedJob }) =>
      JobInfoFooterStyle &&
      css`
        color: ${reportedJob
          ? JobInfoFooterStyle.Colors.reportedLab
          : JobInfoFooterStyle.Colors.reportLab};
      `}
  }
`;

export const NoteDelWrapper = styled.div`
  ${Mixin.Position()};
  padding: 1.5rem;
  text-align: center;
  z-index: 1;
  border-radius: 0.4rem;
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      background-color: ${JobInfoFooterStyle.BgColors.deleteNoteWrap};
    `}
`;

export const ConfirmDelText = styled.p`
  margin: 2rem 0 1.5rem;
`;

export const NoteBtnWrapper = styled.span`
  margin: 0 1rem;
`;

export const NoteLabel = styled.label`
  margin-bottom: 0;
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      color: ${JobInfoFooterStyle.Colors.label};
    `}
`;
export const BtnWrapper = styled.div`
  padding-top: 1.6rem;
  ${Mixin.HorizontalCenter({ justify: 'space-between' })};
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      border-top: 0.1rem solid ${JobInfoFooterStyle.BdrColors.BtnWrapper};
    `}
`;

export const ContentWrapper = styled.div`
  padding: 1.6rem;
`;

export const MessageContent = styled.p`
  margin: 0;
`;

export const PopupFooter = styled.div`
  padding: 0.8rem 1.6rem;
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      border-top: 0.1rem solid ${JobInfoFooterStyle.BdrColors.popupFooter};
    `}

  ${Mixin.HorizontalCenter()};
  column-gap: 1rem;
`;
export const JobDetailsButton = styled.div`
  padding-top: 1.6rem;
  ${Mixin.AllCenter({
    align: 'center',
    justify: 'space-between',
    wrap: 'wrap',
  })}
  ${({ theme: { JobInfoFooterStyle } }) =>
    JobInfoFooterStyle &&
    css`
      border-top: 0.1rem solid ${JobInfoFooterStyle.BdrColors.JobDetailsButton};
    `}
    ${device.mobileLg} {
    display: none;
  }
`;

export const FlagWrapper = styled.div`
  ${Mixin.AllCenter({ justify: 'space-between' })}
`;
