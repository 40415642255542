import { device } from '@styles/device.styled';
import Mixin from '@styles/mixin';
import styled, { css } from 'styled-components';

export const NoteHeader = styled.div`
  ${Mixin.AllCenter({ justify: 'space-between', wrap: 'nowrap' })};
  scroll-margin-top: 19rem;
  margin-bottom: 0.4rem;
  .applyCheckbox {
    ${Mixin.VerticalCenter({ align: 'flex-start' })};
    column-gap: 0.2rem;
    font-weight: 600;
    input {
      width: 1.3rem;
      height: 1.3rem;
    }
  }
`;
export const NotePencilWrap = styled.span`
  font-weight: 600;
  .fa-pencil {
    margin-right: 1rem;
    ${({ theme: { JobNoteStyle } }) =>
      JobNoteStyle &&
      css`
        color: ${JobNoteStyle.Colors.PencilIcon};
      `}
  }
  ${device.mobileLg} {
    .fa-pencil {
      ${({ theme: { JobNoteStyle } }) =>
        JobNoteStyle &&
        css`
          font-size: ${JobNoteStyle.FontSizes.NotePencilWrap};
        `}
    }
  }
`;
export const JobNoteAction = styled.div<{ isJobApply?: boolean }>`
  ${Mixin.VerticalCenter()};
  column-gap: 1rem;
  ${({ isJobApply }) =>
    isJobApply &&
    css`
      .buttondark {
        font-weight: 500;
        ${({ theme: { JobNoteStyle } }) =>
          JobNoteStyle &&
          css`
            color: ${JobNoteStyle.Colors.colorDark};
          `}
      }
    `}
  .cancel-btn {
    ${({ theme: { JobNoteStyle } }) =>
      JobNoteStyle &&
      css`
        color: ${JobNoteStyle.Colors.colorDark};
      `}
  }
`;

export const JobActionWrapper = styled(JobNoteAction)`
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      border-top: 0.1rem solid ${JobNoteStyle.BdrColors.jobActWrap};
    `}
  padding: 2rem 0;
`;

export const CopyLink = styled.a`
  margin-left: auto;
`;

export const DeleteIcon = styled.button`
  margin-left: auto;
  font-weight: 400;
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      color: ${JobNoteStyle.Colors.deleteIcon};
      font-size: ${JobNoteStyle.FontSizes.deleteIcon};
    `}
`;

export const TextAreaWrapper = styled.div<{
  isNoteVal: any;
  isTextFocus: boolean;
}>`
  ${({ theme: { JobNoteStyle }, isNoteVal, isTextFocus }) =>
    JobNoteStyle &&
    css`
      border: 0.1rem solid ${JobNoteStyle.BdrColors.textAreaWrap};
      background: ${isTextFocus || !isNoteVal
        ? JobNoteStyle.BgColors.textAreaWrapfocus
        : JobNoteStyle.BgColors.textAreaWrap};
    `}
  padding: 0.8rem 1.2rem;
  border-radius: 0.4rem;
  position: relative;
`;

export const TextArea = styled.textarea`
  width: 100%;
  background: none;
  border: none;
  resize: none;
  padding: 0.6rem 0;
  margin-bottom: 2.5rem;
  font-family: "Open Sans", Raleway, Arial, sans-serif;
  outline: none;
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      font-size: ${JobNoteStyle.FontSizes.textArea};

      &::placeholder {
        color: ${JobNoteStyle.Colors.placeHolder};
      }
    `}
  &.height-adjust-min {
    height: 5rem;
    margin: 0;
    padding-bottom: 0;
  }
  &.height-adjust-max {
    height: 7rem;
  }

  ${device.mobileLg} {
    ${({ theme: { JobNoteStyle } }) =>
      JobNoteStyle &&
      css`
        font-size: ${JobNoteStyle.FontSizes.textAreaMob};
      `}
  }
`;

export const NoteDelWrapper = styled.div`
  ${Mixin.Position()};
  ${Mixin.AllCenter({ direction: 'column' })}
  padding: 1.5rem;
  text-align: center;
  z-index: 9;
  border-radius: 0.4rem;
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      background-color: ${JobNoteStyle.BgColors.deleteNoteWrap};
    `}
`;

export const ConfirmDelText = styled.p`
  margin: 2rem 0 1.5rem;
`;

export const NoteBtnWrapper = styled.span`
  margin: 0 2.7rem 0 0;
  ${device.mobileLg} {
    margin: 0 1.6rem 0 0;
    .action-btn {
      padding: 0.5rem 0.8rem;
      line-height: 1.5;
    }
  }
`;

export const NoteLabel = styled.label`
  margin-bottom: 0;
  font-weight: 600;
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      color: ${JobNoteStyle.Colors.label};
    `}
`;
export const Icon = styled.i`
  margin-right: 0.5rem;
`;
export const BtnWrapper = styled.div`
  padding-top: 1.6rem;
  ${Mixin.HorizontalCenter({ justify: 'space-between' })};
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      border-top: 0.1rem solid ${JobNoteStyle.BdrColors.BtnWrapper};
    `}
`;

export const NoteHeadWrap = styled.button`
  margin-bottom: 1.5rem;
  background: transparent;
  outline: none;
  border: none;
  .fa-gray {
    ${({ theme: { JobNoteStyle } }) =>
      JobNoteStyle &&
      css`
        color: ${JobNoteStyle.Colors.NoteHeadWrap};
      `}
  }
`;

export const WriteText = styled.span`
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      color: ${JobNoteStyle.Colors.WriteText};
    `}
`;
export const StickyIcon = styled.i`
  margin-right: 1.5rem;
  ${({ theme: { JobNoteStyle } }) =>
    JobNoteStyle &&
    css`
      color: ${JobNoteStyle.Colors.StickyIcon};
    `}
`;

export const ActionBtnWrapper = styled.div`
  ${Mixin.AllCenter()}
`;
