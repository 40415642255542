import { useEffect, useState } from 'react';

const useLazyLoad = () => {
  const [isLazy, setLazy] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setLazy(true);
    };

    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isLazy) {
      document.querySelectorAll('.lazy')?.forEach((e) => {
        e.classList.add('visible');
      });
    }
    if (
      typeof window === 'undefined' ||
      typeof window.IntersectionObserver === 'undefined'
    ) {
      return;
    }
    const observer = new window.IntersectionObserver(
      (entries) => {
        entries?.forEach((entry) => {
          if (entry.isIntersecting) {
            if (entry.target.classList.contains('lazy')) {
              entry.target.classList.add('visible');
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    document.querySelectorAll('.lazy')?.forEach((e) => {
      observer.observe(e);
    });

    return () => {
      document.querySelectorAll('.lazy')?.forEach((e) => {
        observer.unobserve(e);
      });
    };
  }, [isLazy]);

  return isLazy;
};

export default useLazyLoad;
